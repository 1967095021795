<template>
    <div>
        <b-container fluid class="item-title-page">
            {{capitalizeFirstLetterOfAllWordsExceptFirst(infoData.nombre)}}
        </b-container>
        <b-container fluid class="py-5">
            <div class="item-detail">
                <div class="image-section">
                    <b-card-img rounded center fluid :src="infoData.imagen"></b-card-img>
                </div>
                <div>
                    <div>
                        <b><p class="mb-0 product-title"> {{capitalizeFirstLetterOfAllWordsExceptFirst(infoData.nombre)}}</p></b>
                    </div>
                    <hr>
                    <div>
                        <span class="d-block"><b>Referencia: </b> {{infoData.codigo}} </span>
                        <span class="d-block"><b>Código de Barras: </b> {{infoData.codigo_barras}} </span>
                    </div>
                    <hr>
                    <div>
                        <b><p class="mb-0 item-price">$ {{numberFormat(infoData.precio_final_con_imp)}}</p></b>
                    </div>
                    <hr>
                    <div class="alert-quantity-message" v-if="checkHurryUpAlert(infoData)">
                        <div class="d-flex align-items-center">
                            <span class="material-icons-outlined alert-quantity-icon">
                            whatshot
                            </span>
                            <span>
                                ¡APRESÚRATE! SOLO QUEDAN
                                <span class="alert-quantity">{{infoData.quantity}}</span>
                                EN STOCK.
                            </span>
                        </div>
                    </div>
                    <div class="pt-5 " v-if="checkItemExistence(infoData)">
                        <b>Cantidad</b>
                        <div class="d-flex order-quantity-section pt-2">
                            <div class="col-auto pl-0">
                                <b-input-group>
                                <SpinButton 
                                    :value="cantidad"
                                    @input="cantidad = $event"
                                />
                                </b-input-group>
                            </div>

                            <div class="col-auto">
                                <b-button type="submit" variant="info" @click.prevent="addItemToCart(cantidad, infoData)">
                                <b> Añadir al carrito </b>
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <div class="pt-5 producto-agotado" v-else>
                            <strong>Producto Agotado</strong>
                    </div>


                </div>
                <div class="note-ad">
                    <div>
                        <div class="note-add-titles">A tener en cuenta</div>
                    </div>
                    <div>
                        <div class="d-flex align-items-center note-section">
                            <span class="material-icons-outlined note-add-icon mr-2">
                                local_shipping
                            </span>
                            <span class="note-add-titles">
                                SERVICIO DOMICILIO
                            </span>
                        </div>
                        <div class="d-flex align-items-center note-section pt-3">
                            <span class="material-icons-outlined note-add-icon mr-2">
                                savings
                            </span>
                            <span class="note-add-titles">
                                AHORRA CON NUESTROS PRODUCTOS
                            </span>
                        </div>
                        <div class="d-flex align-items-center note-section pt-3">
                            <span class="material-icons-outlined note-add-icon mr-2">
                                safety_check
                            </span>
                            <span class="note-add-titles">
                                COMPRA SEGURA
                            </span>
                        </div>
                        <div class="d-flex align-items-center note-section pt-3">
                            <span class="material-icons-outlined note-add-icon mr-2">
                                currency_exchange
                            </span>
                            <span class="note-add-titles">
                                CAMBIOS Y DEVOLUCIONES
                            </span>
                        </div>
                        <div class="d-flex align-items-center note-section pt-3">
                            <span class="material-icons-outlined note-add-icon mr-2">
                                support_agent
                            </span>
                            <span class="note-add-titles">
                                ATENCIÓN PERSONALIZADA
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <b-row class="pt-5">
                <b-col>
                    <b-card no-body class="card-personalized">
                        <b-tabs tabs align="center" class="pt-3" >
                        <b-tab title="Descripción" class="m-4">
                            <b-card-text v-if="infoData.descripcion_larga" v-html="infoData.descripcion_larga">
                                {{infoData.descripcion_larga}}
                            </b-card-text>
                            <b-card-text v-else>
                                No se ha ingresado una descripción para el producto
                            </b-card-text>
                        </b-tab>
                        </b-tabs>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import SpinButton from '@/components/GlobalComponents/SpinButton.vue'

export default {
    data(){
        return{
            infoData: {},
            cantidad: 1,
        }
    },
    components: {
        SpinButton
    },
    created(){
        this.getItemDetail();
    },
    computed:{
        ...mapState('auth', ['user']),
    },
    methods: {
        ...mapMutations(['setLoading']),
        ...mapActions('configuration', {
          _getCartCount: 'getCartItemsCountByUser',
        }),
        checkItemExistence(item) {
            return (
                item.quantity > 0 && item.precio_final_con_imp > 0
            );
        },
        checkHurryUpAlert(item){
            return (
                 item.quantity < 1000 && item.quantity != 0
            );
        },
        async getItemDetail(){
            this.setLoading(true)
            await this.$conectar.post('/busqueda/get-item-detail', {referencia: this.$route.params.referencia})
            .then(response => {
                this.infoData = response.data.item;
            })
            .catch(response => {
                console.log(response);
            })
            this.setLoading(false)
        },
        volver(){
            this.$router.go(-1);
        },
        async addItemToCart(cantidad, item){
            this.setLoading(true)
            await this.$conectar.post('/articulos/add-item-to-cart', { cantidad: cantidad, referencia: item.codigo, quantity: item.quantity})
            .then(response =>{
                 if (response.data.error == false) { 
                    this._getCartCount();
                    this.$root.$bvToast.toast(response.data.mensaje, {
                        title: "Respuesta del servidor",
                        variant: "success",
                        solid: true
                    })
                }else{
                    this.$root.$bvToast.toast(response.data.mensaje, {
                        title: "Respuesta del servidor",
                        variant: "danger",
                        solid: true
                    })
                }
            })
            .catch(error => {
                this.$bvToast.toast("Parece que hubo un error en el servidor", {
                    title: "Respuesta del servidor",
                    variant: "danger",
                    solid: true
                })
                console.log(error);
            })
            this.setLoading(false);
            this.cantidad = 1;
        }
    }
}
</script>
<style scoped>
.item-title-page{
    background-color: #eee ;
    min-height: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
}
.product-title{
    font-size: 23px;
    color: #273c99 !important;
}

.image-section{
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.card-img { 
    max-width: 100%; 
    max-height: 400px;
    object-fit: scale-down;
}
.color-personalized {
    background: #dcdde1 !important;
}

.alert-quantity-message{
    font-size: 18px;
    font-weight: 700;
    color: #f55;
    padding: 0 0 0 30px;
    display: flex;
    align-items: center;
}

.alert-quantity{
    color: #ec0000;
    font-size: 20px;
    font-weight: 800;
    padding: 0 5px;
}

.alert-quantity-icon{
    color: #ff8c03;
}

.card-personalized {
  min-height: 200px;
}
.row-background-personalized{
    background: white!important;
}

.btn{
    background: linear-gradient(to right, #b00b3c 50%, #138496 50%);
    background-size: 210% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.btn:hover {
  background-position: left bottom;
}

.producto-agotado{
    font-size: 18px;
    color: red;
}

.img-attributes{
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.detail-block{
    display: block;
}

.item-price{
    color: rgb(5, 161, 5) !important;
    font-size: 21px;
}

.note-ad > div {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.note-ad > div:nth-child(1) {
  border-bottom: none;
}

.note-add-titles{
    color: #2b4861;
    font-weight: 600;
    font-size: 18px;
}

.note-add-icon{
    color: #f04706 !important;
    font-size: 40px;
    transition: transform 0.3s ease;
}

.note-section:hover .note-add-icon  {
  transform: scaleX(-1);
}


/* body template of item */
.item-detail {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}


@media (max-width: 768px) {
  .item-detail {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .item-detail {
        grid-template-columns: repeat(3, 1fr);
    }
    .item-detail > div:nth-child(1) {
        grid-column: span 3;
    }

    .item-detail > div:nth-child(2) {
        grid-column: span 2;
    }
}

</style>